import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import curve1 from "../images/shapes/curve1.svg"
import dot from "../images/shapes/dot.svg"
import orange from "../images/shapes/orange.svg"
import content from "../content.json"
import { colors } from "../colors"
import ReadMore from "./ReadMore"

const Section = styled.div`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    background: url(${curve1});
    background-repeat: no-repeat;
    height: 104px;
    width: 173px;
    top: 56px;
    left: -70px;
    z-index: -1;
  }

  @media only screen and (max-width: 1100px) {
    ::before {
      left: -121px;
    }
  }

  @media only screen and (max-width: 992px) {
    ::before {
      left: -44px;
      height: 49px;
      width: 90px;
      background-size: cover;
    }
  }

  @media (min-width: 992px) {
    padding-bottom: 66px;
    position: relative;

    ::after {
      content: "";
      position: absolute;
      height: 89px;
      width: 166.4px;
      left: 26%;
      bottom: 0;
      background: url(${curve1}) no-repeat;
      background-size: cover;
      transform: rotate(-139deg);
    }
  }

  @media (min-width: 1280px) {
    padding-bottom: 96px;
  }
`

const Container = styled.div`
  margin: 48px auto 0;
  max-width: 1024px;
  display: flex;
`

const LeftCol = styled.div`
  flex: 100%;

  @media (min-width: 992px) {
    flex: 50%;

    max-width: max-content;
  }
`

const MainText = styled.div`
  max-width: 280px;
  margin: 0 auto;

  h2 {
    margin-bottom: 0;
  }

  h5 {
    font-weight: normal;
    color: ${colors.button};
    margin-top: 8px;
  }

  @media (min-width: 600px) {
    max-width: 500px;
  }

  @media (min-width: 992px) {
    max-width: 440px;
  }

  @media (min-width: 992px) and (max-width: 1092px) {
    padding-left: 24px;
  }
`

const MobileImg = styled.div`
  display: none;
  position: relative;
  margin: 96px 0;
  ::before {
    content: "";
    position: absolute;
    background: url(${orange});
    background-repeat: no-repeat;
    height: 186px;
    width: 50%;
    top: -77px;
    z-index: -1;
    left: 15%;
  }

  @media only screen and (max-width: 992px) {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    ::before {
      top: -62px;
    }
  }
`

const Dots = styled.div`
  ::before {
    content: "";
    position: absolute;
    background: url(${curve1});
    background-repeat: no-repeat;
    height: 52.31px;
    width: 97.72px;
    bottom: -60px;
    z-index: -1;
    right: 37%;
    background-size: cover;
  }
  ::after {
    content: "";
    position: absolute;
    background: url(${dot});
    background-repeat: no-repeat;
    height: 43px;
    width: 37px;
    bottom: -16px;
    z-index: -1;
    background-size: cover;
    right: 44%;
  }
`

const DesktopImg = styled.div`
  text-align: right;
  position: relative;
  padding-top: 35px;
  display: none;

  ::before {
    content: "";
    position: absolute;
    background: url(${orange});
    background-repeat: no-repeat;
    height: 220px;
    width: 71%;
    top: -38px;
    z-index: -1;
    left: 80px;
    transform: translateX(-50px);
  }

  @media (min-width: 992px) {
    display: block;
    flex: 50%;
    ::before {
      left: 160px;
    }
  }
`

const DesktopDots = styled.div`
  transform: translateX(-75px);

  ::before {
    content: "";
    position: absolute;
    background: url(${curve1});
    background-repeat: no-repeat;
    height: 96px;
    width: 167px;
    bottom: 120px;
    z-index: -1;
    right: -113px;
  }
  ::after {
    content: "";
    position: absolute;
    background: url(${dot});
    background-repeat: no-repeat;
    height: 54px;
    width: 50px;
    bottom: 199px;
    right: -58px;
    z-index: -1;
  }
`
export default function AboutCita() {
  return (
    <Section>
      <Container>
        <LeftCol>
          <MainText>
            <h2>{content.pages.cita.heading}</h2>

            <ReadMore>
              {content.pages.cita.text.map((text, i) => (
                <p key={i}>{text}</p>
              ))}
            </ReadMore>
          </MainText>
          <MobileImg>
            <Dots>
              <StaticImage
                src="../images/marialy/sobre-mobile.png"
                alt="person"
                placeholder="blurred"
              />
            </Dots>
          </MobileImg>
        </LeftCol>

        <DesktopImg>
          <DesktopDots>
            <StaticImage
              src="../images/marialy/sobre.png"
              alt="person"
              placeholder="blurred"
              style={{ borderRadius: "18px" }}
            />
          </DesktopDots>
        </DesktopImg>
      </Container>
    </Section>
  )
}
