import React from "react"
import styled from "styled-components"
import face from "../images/face.svg"
import computer from "../images/icons/computer.png"
import home from "../images/icons/home.png"
import content from "../content.json"
import { colors } from "../colors"
import {Flex} from 'rebass'

const Modality = styled.div`
  position: relative;
  padding: 24px;
  margin: 32px auto 0;
  max-width: 920px;

  ::before {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 260px;
    width: 100%;
    height: 1px;
    background: ${colors.button};
  }

  @media (min-width: 768px) {
    justify-content: space-between;
    padding: 32px;
    ::before {
      height: 2px;
      left: 32px;
      transform: none;
      right: 32px;
      max-width: calc(100% - 64px);
    }
  }
`

const BigBtn = styled.button`
  text-align: center;
  padding: 8px 32px;
  width: 290px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  border-radius: 9px;
  background: white;
  border: none;
  margin: 0 auto;
  color: ${colors.text};
  cursor: pointer;

  ::before {
    content: "";
    position: relative;
    margin-right: 1rem;
    height: 40px;
    width: 40px;
    background-size: cover;
  }

  :first-child {
    margin-bottom: 20px;

    ::before {
      background-image: url(${home});
      right: -7px;
    }
  }

  :last-child {
    ::before {
      background-image: url(${computer});
    }
  }

  @media (min-width: 600px) {
    width: 370px;
    ::before {
      width: 56px;
      height: 56px;
    }
  }

  @media (min-width: 768px) {
    :first-child {
      margin: 0 12px 0 0;
    }

    :last-child {
      margin: 0 0 0 12px;
    }
  }
`

// const Section = styled.section`
//   @media (min-width: 768px) {
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-start;
//     padding: 24px;
//     max-width: 880px;
//     margin: 32px auto;
//     gap: 24px;
//   }

//   @media (min-width: 920px) {
//     padding: 0;
//     margin-bottom: 48px;
//   }
// `

// const Form = styled.form`
//   padding: 32px;
//   max-width: 520px;
//   margin: 0 auto;
//   @media (min-width: 768px) {
//     padding: 0;
//     margin: 0;
//   }
// `

// const Field = styled.div`
//   border: 2px solid #e3e3e3;
//   display: flex;
//   align-items: center;
//   padding: 14px 16px;
//   margin-bottom: 1rem;

//   > input {
//     border: none;
//     outline: none;
//     width: 100%;
//     height: 100%;
//   }

//   > input,
//   > label {
//     text-transform: uppercase;
//     color: ${colors.lightText};
//     font-size: 0.875rem;
//   }

//   > label {
//     color: ${colors.text};
//     margin-right: 1rem;
//     width: max-content;
//     white-space: nowrap;
//   }

//   :focus-within {
//     border-color: ${colors.lightText};
//   }
// `

const Container = styled.div`
  position: relative;
  margin: 40px auto;
  min-height: 180px;
  max-width: 400px;
  padding: 0 50px;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 45px;
    width: 95px;
    height: 180px;
    background: url(${face}) no-repeat;
    background-size: cover;
    z-index: -1;
  }

  p {
    color: ${colors.button};
    line-height: 1.7;
    font-size: 1.5rem;
    width: calc(100% - 95px);
    margin: 0;
  }

  @media (min-width: 600px) {
    min-height: 270px;
    padding: 0;

    &::before {
      width: 135px;
      height: 270px;
      right: 0;
    }

    p {
      font-size: 1.75rem;
      width: calc(100% - 150px);
    }
  }

  @media (min-width: 768px) {
    /* margin: 0; */
    /* width: 400px; */
    max-width: 500px;
  }
`

// const Submit = styled.input`
//   background: ${colors.button};
//   padding: 8px 36px;
//   border-radius: 16px;
//   font-weight: 500;
//   border: none;
//   margin: 0 auto 36px;
//   display: block;
//   cursor: pointer;
//   margin-top: 24px;

//   @media (min-width: 768px) {
//     margin-bottom: 0;
//   }
// `

export default function AboutCard() {
  // const INITIAL_STATE = {
  //   nombre: "",
  //   telefono: "",
  //   apellidos: "",
  //   policy: "",
  // }

  // const reducer = (state, action) => {
  //   switch (action.type) {
  //     case "updateFieldValue":
  //       return { ...state, [action.field]: action.value }

  //     default:
  //       return INITIAL_STATE
  //   }
  // }

  // const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE)

  // const updateFieldValue = field => event => {
  //   dispatch({
  //     type: "updateFieldValue",
  //     field,
  //     value: event.target.value,
  //   })
  // }

  return (
    <>
      <Modality>
        <Flex justifyContent="center">
          <a href={content.pages.cita.btn2.link}>
            <BigBtn>{content.pages.cita.btn2.text}</BigBtn>
          </a>
        </Flex>
      </Modality>      
      <Container>
        <p>{content.pages.cita.call}</p>
      </Container>
    </>
  )
}
