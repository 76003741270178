import React from "react"
import content from "../content.json"
import AboutCita from "../components/AboutCita"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo"
import Signup from "../components/Signup"
import TherapiesServices from "../components/TherapiesServices"

export default function Cita() {
  return (
    <Layout>
      <Seo {...content.pages.cita.seo} />
      <AboutCita />
      <TherapiesServices />
      <Signup />
    </Layout>
  )
}
